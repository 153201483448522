import styles from "./PrivacyPolicy.module.scss";
import logo from "../../images/logo.svg";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <p className={styles.logo_title}>
        <img className={styles.logo} src={logo} alt={"Logo"} />
        <span className={styles.author}>Мельник Андрій Володимирович</span>
      </p>
      <div className={styles.breadscrumbs}>
        <Link to={"/"}>Головна / </Link>Політика конфіденційності
      </div>
      <div className={styles.title}>Політика конфіденційності</div>
      <div className={styles.subtitle}>
        Угода про згоду на обробку персональних даних
      </div>
      <div className={styles.text}>
        <p>
          Замовник (будь-яка дієздатна фізична, фізична особа-підприємець, що
          замовила послуги у освіти на сайті _______________________/ та
          Виконавець (фізична особа – підприємець Мельник Андрій Володимирович,
          зареєстрований в Єдиному державному реєстрі юридичних осіб та фізичних
          осіб – підприємців в установленому чинним законодавством порядку та
          який є платником єдиного податку) приймають ці положення та
          погоджуються з ними:Персональні дані - будь-яка інформація про
          Замовника, у тому числі його ім'я, номер телефону та email, а також
          інша інформація, яку Замовник вказує на свій розсуд.
        </p>
        <p>
          Під обробкою персональних даних Замовника розуміються дії (операції)
          Виконавця зперсональними даними, включаючи збір, систематизацію,
          накопичення, зберігання,уточнення (оновлення, зміну), використання,
          знищення персональних даних.Виконавець інформує Замовника, що всі
          персональні дані Замовника використовуютьсялише з метою підготовки та
          організації якісних освітніх послуг .
        </p>
        <p>
          Мета надання Замовником персональних даних і подальшої обробки їх
          Виконавцем -отримання Замовником якісних освітніх послуг Виконавця
          .Виконавець зобов'язується не надавати ніякої персональної інформації
          про Замовникаіншим приватним особами і організаціям.Замовник надає
          Виконавцю право здійснювати наступні дії (операції) з
          персональнимиданими: збір та накопичення; безстрокове зберігання,
          уточнення (оновлення, зміну);використання; знищення;
          знеособлення.Відправка Замовником виконавцю інформації про себе в
          електронному вигляді є йогобезумовною згодою на обробку його
          персональних даних.
        </p>
      </div>
    </div>
  );
};
