import styles from "./MoreAboutCourse.module.scss";
export const MoreAboutCourse = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <p>Докладно про курс</p>
      </div>
      <div className={styles.description}>
        <p className={styles.topText}>
          Цей курс допоможе вам захистити права військовозобов'язаного на всіх
          етапах мобілізації, від отримання повістки до вступу до лав Збройних
          Сил України. Крім того, ви дізнаєтеся про те, як оскаржити
          неправомірні рішення ВЛК щодо військовозобов'язаних, а також, хто має
          право на виїзд за кордон та як його правильно оформити.
        </p>
        <p className={styles.bottomText}>
          Курс складається із легких для розуміння, структурованих
          відеоматеріалів, що корисні не лише фахівцям юриспруденції, а й
          звичайним українцям, які бажають підвищити власну обізнаність у
          військовому праві.
        </p>
      </div>
    </div>
  );
};
