import styles from "./RefundPolicy.module.scss";
import logo from "../../images/logo.svg";
import { Link } from "react-router-dom";

export const RefundPolicy = () => {
  return (
    <div className={styles.container}>
      <p className={styles.logo_title}>
        <img className={styles.logo} src={logo} alt={"Logo"} />
        <span className={styles.author}>Мельник Андрій Володимирович</span>
      </p>
      <div className={styles.breadscrumbs}>
        <Link to={"/"}>Головна / </Link>
        Політика повернення платежів
      </div>
      <div className={styles.title}>Політика повернення платежів</div>
      <div className={styles.text}>
        <p>
          Трапляються ситуації, коли при роботі з сайтом _______________________
          користувачі помилково оплачують послуги. Тому з огляду на потреби та
          побажання своїх клієнтів, ФОП Мельник А. В. розробив «Політику
          повернення платежів». _______________________ , що оплатили послуги,
          мають можливість звернутися до ФОП Мельник А. В. з метою повернення
          коштів за контактами, які вказані на сайті _______________________
          Рішення про відмову / повернення сплачених коштів приймається ФОП
          Мельник А. В. в односторонньому порядку. При прийнятті даного рішення
          ФОП Мельник А. В.керується наступними правилами:
        </p>
        <span>
          1. Вартість оплати послуг може бути повернута замовнику лише протягом
          одного тижня від моменту оплати послуг.
        </span>
        <span>
          2. Повернення грошових коштів здійснюється лише на ті реквізити, за
          якими був здійснений платіж. Рекомендуємо зберігати документ, який
          підтверджує здійснення платежу.
        </span>
        <span>
          3. Після звернення із запитом про повернення оплати послуг і
          схвальному рішенні ФОП Мельник А. В. замовнику необхідно надати ФОП
          Мельник А. В. такі дані: банківські реквізити, на які необхідно
          перерахувати суму повернення; контактні дані для зв'язку - телефон і
          адресу електронної пошти.
        </span>
        <span>
          4. У разі позитивного рішення про повернення, кошти будуть
          перераховані на зазначені в заяві реквізити протягом 30 календарних
          днів.
        </span>
        <span>
          5. Кошти не повертаються, якщо клієнт придбав нетривалі послуги,
          реалізація яких передбачена за 1-30 днів.5. Кошти не повертаються,
          якщо клієнт придбав послуги, у яких клієнту відразу відкривається
          доступ до всіх необхідних матеріалів.
        </span>
      </div>
    </div>
  );
};
